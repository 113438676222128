<template>
  <div id="app">
    <!-- 日历 -->
    <div class="white_box3">
      <div
          class="orange_title shadow"
          style="font-weight: normal; text-align: center;"
      >
        <div class="nowTime">
          <button
              class="preMon iconfont"
              @click="preMon"
          >&#xeb8b;
          </button>
          <div class="thisMon">
            <span v-text="ynow"></span>年<span v-text="mnow+1"></span>月
          </div>
          <button
              class="nextMon iconfont"
              @click="nextMon"
          >&#xeb88;
          </button>
        </div>
      </div>
      <div class="white_box3_in pdb_0">
        <div id="dateContainer">
          <div
              class="date__showNowTime"
              style="display:none"
          >
            今天是 <span v-text="ynow"></span>年<span v-text="mnow+1"></span>月<span v-text="dnow"></span>日
          </div>

          <table id="table">

          </table>

        </div>

        <div>
          <div
              style="display: flex; justify-content: flex-end; align-items: center; margin-top: 4px; margin-bottom: 12px">
            <div class="red_point"/>
            <div style="color: #A2A2A2; font-size: 12px; line-height: 12px; margin-left: 4px; margin-right: 8px;">
              已停餐
            </div>

            <div class="yellow_point"/>
            <div style="color: #A2A2A2; font-size: 12px; line-height: 12px; margin-left: 4px; margin-right: 8px;">已换餐
            </div>

            <div class="green_point"/>
            <div style="color: #A2A2A2; font-size: 12px; line-height: 12px; margin-left: 4px;">终止停餐</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="white_box3 mgt_3vw">
      <div class="meals_list">
        <div v-for="(option, index) in reservationRecordOnDay" :key="index">
          <div style="display: flex">
            <div style="flex: 1; font-size: 14px; line-height: 14px; color: #222222;">{{ option.bundleName }}</div>
            <label v-if="option.type == 'ENROLL'">终止停餐</label>
            <label v-if="option.type == 'STOP'">停餐</label>
            <label v-if="option.type == 'CHANGE'">换餐</label>
          </div>

          <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 6px;">
            {{ '操作人: ' + option.createdBy }}
          </div>

          <div style="color: #999999; font-size: 12px; line-height: 12px; margin-top: 6px;">
            {{ '操作时间: ' + option.createdTime }}
          </div>

          <div style="height: 1px; background-color: #f1f1f1; margin: 8px 0;"
               v-if="index != (reservationRecordOnDay.length - 1)"></div>

        </div>

        <van-empty class="custom-image" v-if="!reservationRecordOnDay || reservationRecordOnDay.length===0"
                   description="暂无报停餐记录"/>
      </div>
    </div>

    <!-- 按钮 -->
    <div class="white_box3 mgt_3vw" style="position: absolute; bottom: 12px; width: 100%">
      <div class="white_box3_in pdb_0">
        <div class="tree_btn2">
          <ul class="clear">
            <li>
              <router-link to="reservation_stop">
                <button class="button_green">停餐</button>
              </router-link>
            </li>
            <li>
              <router-link to="reservation_enroll">
                <button class="button_green_line">终止停餐</button>
              </router-link>
            </li>
            <li>
              <router-link to="reservation_change">
                <button class="button_green_line">换/加餐</button>
              </router-link>
            </li>
            <li>
              <router-link to="reservationRecordConfirmList">
                <button class="button_green_line">停餐确认</button>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<style scoped>
.orange_title {
  display: block !important;
  align-items: unset;
  justify-content: unset;
  flex-direction: unset;
  flex-wrap: unset;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {Dialog} from 'vant';
import moment from 'moment'

export default {
  data() {
    return {
      customerUser: {},
      // 日历
      newDate: '',//当前的日期的信息
      ynow: '',//当前的年数
      mnow: '',//当前的月份
      dnow: '',//当前的天数
      firstDay: '',//第一天
      firstnow: '',//当前的星期
      m_days: [],//每个月的天数
      tr_str: '',//行数

      loading: false,
      calendarData: [],
      reservationRecordOnDay: []
    };
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    // 日历开始
    getDaysInfo() {
      this.newDate = new Date();
      var _this = this;
      this.sureDate(_this);
    },
    is_leap(year) {//判断是不是闰年
      return (year % 100 == 0 ? (year % 400 == 0 ? 1 : 0) : (year % 4 == 0 ? 1 : 0));
    },
    //下面的是画表格的方法，这个方法会根据数据画出我们需要的表格
    drawTable(jsonHtml) {
      const date = new Date();

      const day = this.newDate.getDate(); //今日日期
      const month = this.newDate.getMonth(); //月份

      var _this = this;
      var innerHTML = `
                  <tr class="week_title">
                    <td class="first">日</td>
                    <td>一</td>
                    <td>二</td>
                    <td>三</td>
                    <td>四</td>
                    <td>五</td>
                    <td class="last">六</td>
                  </tr>`;
      var idx = '', date_str = '', isRed = '', isRed2 = '', hasMsg = '';
      for (var i = 0; i < _this.tr_str; i++) {
        innerHTML += '<tr>';
        // 遍历每个星期
        for (var k = 0; k < 7; k++) {

          idx = i * 7 + k;
          isRed2 = (k === 0 || k === 6) ? "isRed2" : "";
          date_str = idx - _this.firstnow + 1;
          (date_str <= 0 || date_str > this.m_days[this.mnow]) ? date_str = " " : date_str = idx - _this.firstnow + 1;
          // changeDate

          // console.log('=======');
          // console.log('date_str = ' +date_str);
          // console.log('_this.dnow = ' +_this.dnow);
          // console.log('day = ' +day);
          // console.log('_this.mnow = ' +_this.mnow);
          // console.log('month = ' +month);

          if (date_str == _this.dnow && _this.mnow == month) {
            // console.log('1=======');
            // console.log('date_str = ' +date_str);
            // console.log('_this.dnow = ' +_this.dnow);
            // console.log('day = ' +day);
            // console.log('_this.mnow = ' +_this.mnow);
            // console.log('month = ' +month);
            hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" class="thisDay" date="' + date_str + '"><span class="' + isRed2 + '">' + date_str + '</span></td>';
          } else {
            if (date_str == date.getDate() && _this.mnow == date.getMonth()) {
              // console.log('2=======');
              // console.log('date_str = ' +date_str);
              // console.log('_this.dnow = ' +_this.dnow);
              // console.log('day = ' +day);
              // console.log('_this.mnow = ' +_this.mnow);
              // console.log('month = ' +month);
              // 显示当前日期，但不是当前选择的日期
              hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" class="nowDay" date="' + date_str + '"><span class="' + isRed2 + '">' + date_str + '</span></td>';
            } else {
              hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" date="' + date_str + '"><span class="' + isRed + '">' + date_str + '</span></td>';
            }
          }

          for (var l = 0, len = jsonHtml.length; l < len; l++) {
            if (date_str == jsonHtml[l].date) {
              var newStr = '<div style="display: flex;\n' +
                  '    position: absolute;\n' +
                  '    bottom: 0;\n' +
                  '    margin: 0;\n' +
                  '    padding: 0;\n' +
                  '    left: 50%;\n' +
                  '    transform: translate(-50%, -0%);\n' +
                  '    width: 50%;\n' +
                  '    height: 20%;\n' +
                  '    justify-content: center;">';

              for (let j = 0; j < jsonHtml[l].class.length; j++) {
                if (j === 0) {
                  newStr += '<div class="' + jsonHtml[l].class[j] + '"></div>'
                } else {
                  newStr += '<div class="' + jsonHtml[l].class[j] + '" style="margin-left: 4px;"></div>'
                }
              }

              newStr += '</div>';

              if (date_str == _this.dnow && _this.mnow == month) {
                // console.log('3=======');
                // console.log('date_str = ' +date_str);
                // console.log('_this.dnow = ' +_this.dnow);
                // console.log('day = ' +day);
                // console.log('_this.mnow = ' +_this.mnow);
                // console.log('month = ' +month);
                hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" class="thisDay" date="' + date_str + '"><span class="' + isRed2 + '">' + date_str + '</span>' + newStr + '</td>';
              } else {
                if (date_str == date.getDate() && _this.mnow == date.getMonth()) {
                  // console.log('2=======');
                  // console.log('date_str = ' +date_str);
                  // console.log('_this.dnow = ' +_this.dnow);
                  // console.log('day = ' +day);
                  // console.log('_this.mnow = ' +_this.mnow);
                  // console.log('month = ' +month);
                  hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" class="nowDay" date="' + date_str + '"><span class="' + isRed2 + '">' + date_str + '</span>' + newStr + '</td>';
                } else {
                  hasMsg = '<td onclick="changeDate(\'' + date_str + '\');" date="' + date_str + '"><span class="' + isRed + '">' + date_str + '</span>' + newStr + '</td>';
                }
              }
            }
          }
          innerHTML += hasMsg;
        }
        innerHTML += '</tr>';
      }
      var table = document.getElementById('table');
      table.innerHTML = innerHTML;
    },
    //两个参数代表的含义分别是this对象以及判断当前的操作是不是在进行月份的修改
    sureDate(_this, other, nDate) {
      this.ynow = this.newDate.getFullYear();
      if (!other) {
        this.mnow = this.newDate.getMonth(); //月份
      }
      if (!other || other !== 'day') {
        this.dnow = this.newDate.getDate(); //今日日期
      }
      // console.log('this.ynow', this.ynow)
      // console.log('this.mnow', this.mnow)
      // console.log('this.dnow', this.dnow)
      this.firstDay = new Date(this.ynow, this.mnow, 1);
      this.firstnow = this.firstDay.getDay();
      this.m_days = [31, 28 + this.is_leap(this.ynow), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      this.tr_str = Math.ceil((_this.m_days[this.mnow] + this.firstnow) / 7);

      var month = this.firstDay.getMonth() + 1;
      var customerUser = Vue.ls.get(ACCESS_CustomerUser)
      this.calendarData = []
      this.loading = true
      post('api/reservation/v2/find/between', {
        businessCustomerUserId: customerUser.businessCustomerUserId,
        // startDay: this.firstDay.getTime(),
        // endDay: new Date(this.firstDay.getFullYear(), month, 0).getTime()
        startDay: moment(this.firstDay.getTime()).format('YYYY-MM-DD'),
        endDay: this.firstDay.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + new Date(this.firstDay.getFullYear(), month, 0).getDate()
      }).then(res => {
        this.calendarData = res.data;

        this.reservationRecordOnDay = []
        let rq = moment(this.newDate.getTime()).format('YYYY-MM-DD')
        this.calendarData.forEach(item => {
          if (item.day === rq) {
            this.reservationRecordOnDay.push({
              day: item.day,
              id: item.id,
              type: item.type,
              bundleName: item.productBundle ? item.productBundle.name : '-',
              createdBy: item.createdBy,
              createdTime: moment(item.createdTime).format('YYYY-MM-DD HH:mm:ss')
            });
          }
        })
        // console.log(this.reservationRecordOnDay)
        this.loading = false
        this.showMsg();
      }).catch(() => this.loading = false)
    },
    preMon() {
      var _this = this;
      if (this.mnow > 0) {
        this.mnow--;
      } else {
        this.ynow--;
        this.mnow = 11;
      }
      this.newDate = new Date(this.ynow + '/' + (this.mnow + 1) + '/' + this.dnow);
      this.sureDate(_this, "up");
    },
    nextMon() {
      var _this = this;
      if (this.mnow < 11) {
        this.mnow++;
      } else {
        this.ynow++;
        this.mnow = 0;
      }
      this.newDate = new Date(this.ynow + '/' + (this.mnow + 1) + '/' + this.dnow);
      this.sureDate(_this, "next");
    },
    //通过接口返回的是我们当前的月份对应在日历中需要处理的事项
    showMsg() {
      var calendarOptions = [];
      this.calendarData.forEach(item => {
        let type = item.type;
        let rq = item.day;
        let day = parseInt(item.day.split('-')[2]);
        let exist = calendarOptions.find(el => el.rq === rq);
        let className = []

        let existIndex = calendarOptions.findIndex(el => el.rq === rq);

        if (!exist) {
          if (type === 'ENROLL') {
            // 绿色
            className.push('green_point')
          }
          if (type === 'STOP') {
            // 红色
            className.push('red_point')
          }
          if (type === 'CHANGE') {
            // 黄色
            className.push('yellow_point')
          }
          calendarOptions.push({date: day, class: className, rq: rq});
        } else {
          if (type === 'ENROLL' && calendarOptions[existIndex].class.findIndex(el => el === 'green_point') <= -1) {
            // 绿色
            calendarOptions[existIndex].class.push('green_point');
          }
          if (type === 'STOP' && calendarOptions[existIndex].class.findIndex(el => el === 'red_point') <= -1) {
            // 红色
            calendarOptions[existIndex].class.push('red_point')
          }
          if (type === 'CHANGE' && calendarOptions[existIndex].class.findIndex(el => el === 'yellow_point') <= -1) {
            // 黄色
            calendarOptions[existIndex].class.push('yellow_point')
          }
        }
      });
      this.drawTable(calendarOptions)
    },
    changeDate(date) {
      this.dnow = date;
      this.mnow = this.firstDay.getMonth();
      this.newDate = new Date(this.ynow + '/' + (this.mnow + 1) + '/' + this.dnow);
      this.sureDate(this, 'day');
    },

    onClickBanner() {
      this.showGFActivityPopup = false;
      if (!this.customerUser || !this.customerUser.businessCustomerUser) {
        return;
      }
      window.location.href = 'https://www.wjx.top/vm/Y23FSOc.aspx#';
    },
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    let that = this;

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);

    window.changeDate = that.changeDate;
    //  日历
    //画出当前的月份的天数对应的表格
    this.getDaysInfo();
    //进行数据的获取，显示到对应的位置
  }
};
</script>

<style>
#dateContainer {
  width: 100%;
}

#dateContainer .nowTime {
  display: flex;
  justify-content: center;
  height: 30px;
  line-height: 30px;
}

#dateContainer .nowTime .thisMon {
  height: 30px;
  line-height: 30px;
}

table {
  width: 100%;
  border-collapse: collapse; /* 边框合并属性  */
  padding: 0;
  border: 0px;
  margin: 12px 0 0 0;
}

table tr {
  /*border: 2px solid #666666;*/
}

table tr:last-child {
  border: none;
}

table tr td {
  /*border: 2px solid #666666;*/
  text-align: center;
  width: 14.2857%;
  height: 11vw;
  position: relative;
  font-size: 12px;
}

table tr td span[date] {
  position: relative;
  z-index: 999;
}

table tr td div {

}

.red_point {
  width: 4px;
  height: 4px;
  background-color: #ED3B3B;
  border-radius: 4px;
}

.yellow_point {
  width: 4px;
  height: 4px;
  background-color: #fadc34;
  border-radius: 4px;
}

.green_point {
  width: 4px;
  height: 4px;
  background-color: #34ac40;
  border-radius: 4px;
}

table tr td div.line {
  width: 3px;
}

/*table tr td div p {*/

/*}*/

/*table tr td p.red {*/
/*  border-top: 9px solid #ED3B3B;*/
/*  border-left: 9px solid transparent;*/
/*  color: #fff;*/
/*}*/

/*table tr td p.yellow {*/
/*  border-top: 9px solid #fadc34;*/
/*  border-left: 9px solid transparent;*/
/*  color: #fff;*/
/*}*/

/*table tr td p.green {*/
/*  border-top: 9px solid #34ac40;*/
/*  border-left: 9px solid transparent;*/
/*  color: #fff;*/
/*}*/

table tr td p.none {
  background: none;
}

td.thisDay {
  /* background-color: #eee; */
  background: none;
}

td.thisDay span {
  color: #fff;
  /*background: rgb(79, 143, 203);*/
  background-color: #34AC40;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  border: 2px #34AC40 solid;
  overflow: hidden;
}

td.nowDay {
  /* background-color: #eee; */
  background: none;
}

td.nowDay span {
  color: #34AC40;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  border: 2px #34AC40 solid;
  overflow: hidden;
}

.isRed {
  color: red;
}

.isRed2 {
  color: inherit;
}

tr.week_title {
  border-top: unset;
}

tr.week_title td {
  height: 24px;
  line-height: 24px;
  border-top: unset;
  background-color: #F2F2F2;
  color: #888888;
}

tr.week_title td.first {
  border-radius: 12px 0px 0px 12px;
}

tr.week_title td.last {
  border-radius: 0px 12px 12px 0px;
}

</style>
